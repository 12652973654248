import React from "react";
import {
  changeAnalysisParam,
  transformWaterFallData,
  getWaterFallDataByView,
  getWaterFallDataByRange,
} from "./utils/waterfall";
import Stack from "./Stack";
import Auth from "./components/Auth";
import ResetPassword from "./components/Auth/ResetPassword";
import Dashboard from "./components/Dashboard";
import NotFound from "./components/NotFound/index.jsx";
import { connect } from "react-redux";
import "./App.scss";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import { createAxiosResponseInterceptor } from "./axios-interceptor";
import IdleTimer from "react-idle-timer";
import NewDashboard from './components/SubApp/index.jsx';
import RouteProfitability from './components/RouteProfitability/index.jsx';
import "./responsive.scss";
import './assets/fonts/fonts.css';


export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesData: [],
      loading: true,
      revenueAnalysisData: [],
      costAnalysisData: [],
      drillDownDataTitle: "",
      xAxisLabel: "",
      hasError: false,
      yAxisLabel: "",
      logoutFlag: 0,
    };
    let locationHref = window.location.href
    if (locationHref && locationHref.includes('embed')) {
      sessionStorage.setItem('embedIframe', true)
      this.props.performEmbedHandling()
    }
  }

  componentDidMount() {
    createAxiosResponseInterceptor(this.props.performLogout);
    String.prototype.toProperCase = function () {
      return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    };

  }

  componentDidUpdate() {
  }

  changeDataByRange = (data) => {
    this.setState({ loading: true });
    getWaterFallDataByRange(data).then((response) => {
      let transformedData = transformWaterFallData(response.data.series_data);
      this.setState({ loading: false, seriesData: transformedData });
    });
  };

  changeDataByView = (data) => {
    this.setState({ loading: true });
    getWaterFallDataByView(data).then((response) => {
      let transformedData = transformWaterFallData(response.data.series_data);
      this.setState({ loading: false, seriesData: transformedData });
    });
  };

  changeDataByAnalysis = (data) => {
    this.setState({ loading: true });
    const seriesData = changeAnalysisParam(data, this.state.seriesData);
    this.setState(
      { loading: false, drillDownDataTitle: data, seriesData },
      () => {
        console.log(this.state);
      }
    );
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }


  render() {
    // let idleTime = ""
    // if (localStorage.length != 0) {
    //   idleTime = (<IdleTimer
    //     ref={(ref) => {
    //       this.idleTimer = ref;
    //     }}
    //     timeout={1000 * 60 * 30}
    //     onIdle={this.handleOnIdle}
    //     debounce={250}
    //   />)
    // }

    return (
      <Router history={history}>
        {/* {idleTime} */}
        <Switch>
          <Route path="/stack">
            <Stack />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/usermanagement">
            <Dashboard />
          </Route>
          <Route path="/not-found">
            <NotFound />
          </Route>
          <Route path="/new-dashboard">
              <NewDashboard />
          </Route>
          <Route path="/route-profitability">
              <RouteProfitability />
          </Route>
          <Route path="/">
            <Auth />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      performLogout(data) {
        dispatch({
          type: "PERFORM_LOGOUT",
          payload: data,
        });
      },
      performEmbedHandling() {
        dispatch({
          type: "PERFORM_EMBED_HANDLING"
        });
      },
    };
  }
)(App);